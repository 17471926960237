import { persistor } from 'index';
//import React, { forwardRef } from 'react';
import React, { useState, forwardRef } from 'react';
import { useNavigate } from 'react-router-dom';


function MenuLeft({ props, forwardedRef, setIsMenuLeftVisible, setIsSearch, isSearch}: any) {
  const navigation = useNavigate();

  const onMenu01Click = () => {
    //setIsComponentVisible(false);
    setIsMenuLeftVisible(false);
    navigation('/wikiDetail?title=아큐피디아(ACUPEDIA)');
  };
  
  const onMenu02Click = () => {
    setIsMenuLeftVisible(false);
    navigation('/libraryDetail?title=학술자료庫%20목록');
  };

  const onMenu03Click = () => {
    setIsMenuLeftVisible(false);
    setIsSearch(!isSearch);
  };  
 
  return (
    <div
      {...props}
      ref={forwardedRef}
      className="overflow-hidden pt-[12px] absolute w-[264px] bg-white shadow-xl left-0 rounded-lg mt-9">
      <p className="pl-[22px] text-[13px] font-normal opacity-50 h-[47px] flex items-center">
        메뉴
      </p>
            
      <div
        onClick={onMenu01Click}
        className="hover:bg-[#EFEFEF] hover:rounded-lg cursor-pointer h-10 flex items-center">
        <p className="pl-[22px] text-[15px] font-semibold">위키</p>
      </div>
      <div
        onClick={onMenu02Click}
        className="hover:bg-[#EFEFEF] hover:rounded-lg cursor-pointer h-10 flex items-center">
        <p className="pl-[22px] text-[15px] font-semibold">학술자료庫</p>
      </div>
      <div 
        onClick={onMenu03Click} 
        className="hover:bg-[#EFEFEF] hover:rounded-lg cursor-pointer h-10 flex items-center">
        <p className="pl-[22px] text-[15px] font-semibold">고전침구처방검색</p>
      </div> 

    </div>
  );
}

export default forwardRef(MenuLeft);



